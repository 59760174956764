.mult-play-button {
    font-weight: bold;
    font-style: italic;
    border: none;
    margin-top: 10px;
    width: 540px;
    height: 180px;
    font-size: 40px;
    font-family: 'Inter', sans-serif;
    color: white;
    background-color: #2D2D2D;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .mult-play-button:hover {
    border: none;
    background-color: var(--color-dark);
  }

  .mobile-mult-play-button {
    font-weight: bold;
    font-style: italic;
    width: 20%;
    border: none;
    font-size: 20px;
    font-weight: normal;
    margin-right: 20px;
    padding: 10px 10px;
    padding-right: 60px;
    font-family: 'Inter', sans-serif;
    background-color: #2D2D2D;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .mult-container {
    margin-top: 40px;
    margin-bottom: 15px;
  }

  .center-button-container {
    display: flex;
    justify-content: center;
    align-items: center;     
    height: 60vh;
    flex-direction: column;
  }

  .mult-queue-message {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin-top: 50px;
    font-size: 40px;
  }

  .mult-queue-subtext {
    font-family: 'Inter', sans-serif;
    margin-top: 30px;
    font-size: 20px;
  }

  .mobile-queue-subtext {
    font-family: 'Inter', sans-serif;
    margin-top: 10px;
    font-size: 14px;
  }

  .mobile-mult-container {
    margin-top: 40px;
    margin-bottom: 15px;
  }

  .mobile-button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;     
    height: 60vh;
    flex-direction: column;
  }

  .mobile-queue-message {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    margin-top: 40px;
    font-size: 25px;
    margin-bottom: 10px;
  }

  .mobile-play-button {
    font-weight: bold !important;
    font-style: italic;
    height: 20%;
    width: 50%;
    border: none;
    font-size: 20px;
    font-weight: normal;
    font-family: 'Inter', sans-serif;
    background-color: #2D2D2D;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center; 
    align-items: center;      
    text-align: center;
    padding-left: 20px;  
    padding-right: 20px;     
  }

  .mobile-play-button:hover {
    border: none;
    background-color: var(--color-dark);
  }

  .mobile-mult-header {
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 35px;
    font-style: italic;
  }