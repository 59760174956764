.alert-container {
    position: fixed;
    top: 27px;
    right: 27px;
    background-color: var(--color-dark);
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 1001;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    align-self: center; 
}

.alert-button {
    margin-left: 20px;
    background-color: var(--color-light);
}

.mobile-alert-container {
    position: absolute; 
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    width: 80%;

    background-color: var(--color-dark);
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 1001;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    align-self: center; 
}