/* multiplayerMatchComplete.css */

/* The container for the match results overlay */
.mult-match-complete {
    position: fixed;      /* Make it float above other elements */
    top: 50%;             /* Center vertically */
    left: 50%;            /* Center horizontally */
    transform: translate(-50%, -50%); /* Perfectly center the box */
    z-index: 9999;        /* Ensure it's above most page elements */
  
    background-color: #fff;   /* White background */
    color: #333;              /* Dark text */
    border: 2px solid #333;   /* Simple border */
    border-radius: 8px;       /* Slightly rounded corners */
    padding: 20px;            /* Some space inside */
    box-shadow: 0 4px 8px rgba(0,0,0,9.3); /* Subtle shadow */
    max-width: 550px;         /* Constrain width */
    width: 80%;               /* Responsive width for smaller screens */
    text-align: center;       /* Center text */
    min-height: 300px;
  }
  
  /* The row with both players' times */
  .match-times {
    display: flex;
    justify-content: space-around;  /* space them apart horizontally */
    margin: 16px 0;
  }
  
  /* Slight spacing for the button */
  .mult-match-complete button {
    margin-top: 15px;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
  
    border: 1px solid #333;
    border-radius: 4px;
    background-color: #eee;
    transition: background-color 0.2s;
  }
  
  .mult-match-complete button:hover {
    background-color: #ddd;
  }
  
  .mc-header {
    font-size: 35px;
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-weight: 700;
    margin-bottom: 45px
  }

  .mobile-mc-header {
    font-size: 25px;
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-weight: 700;
    margin-bottom: 35px;
    text-align: center;
    margin-top: 30px;
  }

  .timer-outer-container {
    display: flex;
    gap: 60px;
  }

  .player-username {
    font-size: 21px;
    font-family: 'Inter', sans-serif;
    margin-bottom: 5px;
  }

  .mc-popup-banner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    position: fixed;
    width: 590px; 
    height: 450px; 
  
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
  
    color: rgb(46, 46, 46); 
    background-color: rgb(255, 255, 255);
    border: 4px solid #2D2D2D;
    padding: 20px;
    padding-bottom: 0px; 
    padding-top: 0px; 
    z-index: 10; 
  }

  .mobile-mc-popup-banner {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); 
    position: fixed;
    width: 70%; 
    height: fit-content; 
  
    display: flex;
    flex-direction: column; 
    justify-content: flex-start; 
    align-items: center; 
  
    color: rgb(46, 46, 46); 
    background-color: rgb(255, 255, 255);
    border: 4px solid #2D2D2D;
    padding: 20px;
    padding-bottom: 0px; 
    padding-top: 0px; 
    z-index: 10; 
  }

  .timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 45px
  }

  .mobile-timer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px
  }

  .mc-timer-box {
    display: flex;
  }

  .mc-milliseconds {
    padding-top: 22px;
    padding-left: 4px;
    font-weight: normal;
    font-size: 16px;
    color: #666;
    font-style: italic;
    font-family: 'Inter', sans-serif !important;
  }
  
  .mc-timer-banner {
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-size: 35px;
    text-align: center;
    margin-top: 5px;
  }

  .mc-message {
    margin-bottom: 40px;
    font-size: 30px;
    font-family: 'Inter', sans-serif !important;
  }

  .mobile-mc-message {
    margin-bottom: 40px;
    font-size: 23px;
    font-family: 'Inter', sans-serif !important;
    text-align: center;
  }

  .mc-close-button {
    width: 250px;
    height: 50px;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    color: white;
    background-color: #2D2D2D;
    cursor: pointer;
    transition: background-color 0.3s;
}

.mc-close-button:hover {
    border:none;

    background-color: var(--color-normal); 
    color: #2D2D2D;
}

.mobile-mc-close-button {
  width: 250px;
  height: 50px;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  color: white;
  background-color: #2D2D2D;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobile-mc-close-button:hover {
  border:none;

  background-color: var(--color-normal); 
  color: #2D2D2D;
}