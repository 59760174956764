/* Default styles for desktop */
.main-layout .mobile-only {
  display: none;
  margin-bottom: 15px;
}

.main-layout .desktop-only {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

/* Styles for mobile devices */
@media (max-width: 768px) {
  .main-layout .mobile-only {
    display: flex;
    flex-direction: column;
  }

  .main-layout .desktop-only {
    display: none;
  }
}

/* Ensure the html, body, and root take up the full height */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.main-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.mobile-header {
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: space-between;
  z-index: 20; 
  position: relative; 
  padding: 20px;
  padding-left: 3px;

  align-items: center;
  text-align: center;

  margin-top: 20px;
  margin-bottom: 20px;
}

.header {
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 20; 
  position: relative; 
}

.logout-container {
  margin-top: 60px;
  margin-left: 60px;
  font-size: 30px;
  display: flex;
  flex-direction: column; 
  align-items: center; 
  font-style: italic;
}

.logout-button {
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  color: #2D2D2D;
  font-size: 50px;
  justify-self: flex-start; 
  align-items: center; 
  gap: 8px; 
  transition: color 0.3s;
}

.mobile-logout-button {
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  color: #2D2D2D;
  margin-top: 4px;
  font-size: 50px;
  justify-self: flex-start; 
  align-items: center; 
  transition: color 0.3s;
}

.logout-button i {
  color: #2D2D2D;
  transition: color 0.3s;
}

.logout-button:hover i,
.mobile-logout-button:hover,
.logout-button:hover span {
  color: var(--color-normal);
}

.logout-button span {
  font-size: 30px;
  color: #2D2D2D;
  transition: color 0.3s;
  font-style: italic;
}

.greeting {
  color: #2D2D2D;
  margin-top: 8px; 
}

.mobile-title {
  color: #2D2D2D;
  width: 250px;
  font-size: 40px;
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
}

.title {
  text-align: right;
  width: 400px;
  color: #2D2D2D;
  margin-top: 60px;
  margin-right: 70px;  
  margin-left: auto; 
  font-size: 56px;
  font-family: 'Inter', sans-serif;
  font-style: italic;
}

.mobile-choicesWrapper {
  display: flex;
  flex-direction: column;
  font-size: 45px;
  margin-top: auto;
  margin-bottom: 30px;
  margin-right: 20px;
}

.choicesWrapper {
  display: flex;
  margin-top: auto;
  margin-bottom: 30px;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  font-style: italic;
  padding: 20px;
  padding-right: 0px;
  width: 100%; /* Take full width of the screen */
}

.choiceContainerRight, .choiceContainerLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
  width: 50%; /* Take half of the screen width */
}

.choiceContainerRight {
  text-align: right;
  margin-right: 80px;  
}

.choiceContainerLeft {
  text-align: left;
  margin-left: 40px;  
}

.mobile-choice a {
  text-decoration: none; 
  color: white;
  width: 90%;
  font-size: 27px; 
  font-family: 'Inter', sans-serif;
  font-style: italic;
  padding: 20px 20px; 
  display: block; 
  transition: background-color 0.3s, font-weight 0.08s;

  margin-bottom: 12px;
  align-self: center;
  justify-content: center;

  background-color: var(--color-dark);

  clip-path: polygon(0 0, 100% 0, 100% 0%, 80% 100%, 0 100%);
}

.mobile-choice a:hover {
  font-weight: bold;
  cursor: pointer;
}

.choice a {
  text-decoration: none; 
  color: #2D2D2D;
  font-size: 35px; 
  font-family: 'Inter', sans-serif;
  font-style: italic;
  padding: 10px 20px; 
  display: block; 
  transition: background-color 0.3s, font-weight 0.08s;
}

.choice a:hover {
  font-weight: bold;
  background-color: var(--color-light);
  cursor: pointer;
}

.public-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
