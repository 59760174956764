.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    text-align: center;
    justify-content: center; 
    font-family: 'Inter', sans-serif;
    height: 90vh; 
}

.auth-header {
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-weight: bold;
}

.username-input,
.password-input {
    width: 500px; 
    max-width: 400px;
    padding: 10px;
    margin-bottom: 10px; 
    font-size: 20px;
    border: 1px solid #ccc;
}

.username-input:focus,
.password-input:focus,
.mobile-username-input:focus,
.mobile-password-input:focus,
.mobile-register-password-input:focus{
    border-color: #FF6961;
    outline: none;
}

.username-input:hover,
.password-input:hover,
.mobile-username-input:hover,
.mobile-password-input:hover,
.mobile-register-password-input:hover {
    background-color: #f0f0f0;
}

.submit-button {
    margin-top: 10px;
    width: 280px; 
    max-width: 400px; 
    height: 50px;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    color: white;
    background-color: #2D2D2D;
    cursor: pointer;
    border: 1.5px solid #2D2D2D;
    transition: background-color 0.3s, color 0.3s; 
}

.submit-button:hover,
.mobile-submit-button:hover{
    border: none;
    background-color: #FF6961;
    color: #2D2D2D;
}

.register-link {
    margin-top: 20px;
    font-size: 16px;
}

.mobile-register-link {
    margin-top: 10px;
    font-size: 16px;
}

.register-link a,
.mobile-register-link a {
    color: #2D2D2D;
    text-decoration: none;
    font-weight: bold;
}

.register-link a:hover,
.mobile-register-link a:hover {
    text-decoration: underline;
}

.register-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.register-error-text {
    margin: 10px;
    font-style: italic;
  }

  .temp-error {
    animation: tempErrorAnimation 2s ease-in-out;
  }
  
  @keyframes tempErrorAnimation {
    0% {
      color: #fa655c;
    }
    100% {
      color: black;
    }
  }
  
.mobile-auth-header {
    margin-bottom: 15px;
    font-family: 'Inter', sans-serif;
    font-size: 27px;
    font-weight: bold;
}

.mobile-username-input,
.mobile-password-input {
    width: 300px;
    max-width: 400px;
    padding: 10px;
    margin-bottom: 14px; 
    font-size: 20px;
    border: 1px solid #ccc;
}

.mobile-register-password-input {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    margin-bottom: 25px; 
    font-size: 20px;
    border: 1px solid #ccc;
}

.mobile-submit-button {
    margin-top: 4px;
    width: 80%; 
    max-width: 400px; 
    height: 45px;
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    color: white;
    background-color: #2D2D2D;
    cursor: pointer;
    border: 1.5px solid #2D2D2D;
    transition: background-color 0.3s, color 0.3s; 
}

.mobile-register-submit-button {
    margin-top: 16px;
    width: 60%; 
    max-width: 400px; 
    height: 45px;
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    color: white;
    background-color: #2D2D2D;
    cursor: pointer;
    border: 1.5px solid #2D2D2D;
    transition: background-color 0.3s, color 0.3s; 
}

.mobile-auth-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    text-align: center;
    justify-content: center; 
    font-family: 'Inter', sans-serif;
    height: 90vh; 
    width: 100%;
}

.google-btn {
    font-family: 'Inter', sans-serif;
    display: inline-flex;
    align-items: center;
    padding: 0.65rem 1rem;
    background-color: #fff;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-top: 20px;
  }
  
  .google-btn:hover {
    background-color: #f8f8f8;
  }
  
  .google-logo {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }