
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 2rem;
    text-align: center;
    border: 3px solid #2d2d2d;
    max-width: 500px;
    width: 440px;
    font-size: 25px;
    font-family: 'Inter', sans-serif;
  }
  
  .modal-content h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #333;
  }
  
  .modal-content p {
    margin-bottom: 1.5rem;
    font-size: 18px;
    color: #555;
  }
  
  .sp-close-button {
    color: white;
    background-color: #2D2D2D;   
    margin-top: 15px; 
    border: none;
    width: 200px;
    padding: 15px;
    cursor: pointer;
    font-size: 20px;
    transition: background 0.3s ease;
  }
  
  .sp-close-button:hover {
    background: var(--color-dark);
    color: white;
  }
  