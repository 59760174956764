/* Container for the entire dashboard */
.main-container {
  display: flex;
    margin: 0; /* Reset margin */
    padding: 0; /* Reset padding */
}

/* Disable scrolling */
.no-scroll {
  overflow: hidden;
  height: 100%;
  touch-action: none; /* Prevent touch interactions */
}
@keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.input--focused{
  animation: blink_input_opacity_to_prevent_scrolling_when_focus 0.01s;
}

/* Desktop styles */
.dashboard-container .mobile-only {
  display: none;
}

.dashboard-container .desktop-only {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Inter', sans-serif;
  margin-left: 40px;
  margin-top: 10px;
}

/* Styles for mobile devices */
@media (max-width: 768px) {
  .dashboard-container .mobile-only {
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    height: 100vh;
  }

  .dashboard-container .desktop-only {
    display: none;
  }
}

/* Media queries for different screen sizes */
.grid {
  display: flex;
  flex-direction: column;
  border: 4px solid #2D2D2D;
  align-items: flex-end;

  position: relative;

  transform-origin: top left;

  width: fit-content !important;
  max-width: 566px;

  justify-self: center;
}

/* desktop */
@media (min-width: 769px) {
  .dashboard-container .desktop-only {
    display: flex;
  }

  .dashboard-container .mobile-only {
    display: none;
  }
}

.mobile-grid-container {
  margin-left: 10px;
  width: 0%;
  height: fit-content;
}

.row {
  display: flex;
}

.cell {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #2D2D2D;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  caret-color: transparent;    
}

.cell:focus-within {
  background-color: var(--color-normal) !important;
}

.cell-gray:focus-within {
  background-color: #676767 !important;
}

.cell-selected {
  background-color: var(--color-normal) !important;
}

.cell div[contenteditable="true"]:focus {
  outline: none !important;
}

.black {
  background-color: #000000;
  pointer-events: none;
}

.crossword-container {
  margin: 80px;
  margin-top: 0px;
  margin-right: 50px;
  width: 566px;
  height: fit-content;
  display: grid;
  justify-content: center;
}

.hint {
  background-color: var(--color-light); 
  color: #000000;            
  font-family: 'Inter', sans-serif;
  font-size: 20px;           
  padding: 30px 40px;       
  box-sizing: border-box;     
  margin: 20px auto;         
  text-align: left;      
  width: 95.5%;
  height: fit-content;
}

.loading-container {
  display: flex;
  align-items: center; 
  justify-content: center; 
  height: 100vh; 
  width: 100%;
  position: fixed; 
  top: 0;
  left: 0;
  color: #fff; 
  font-size: 1.5em; 
  z-index: 1000;
}

.loading-message {
  padding: 30px;
  width: 300px;
  margin-bottom: 80px;
  text-align: center;  
  background-color: #2D2D2D;
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-size: 24px;
  border-radius: 10px;
}

.timer {
  font-family: 'Inter', sans-serif;
  width: 200px;
  font-style: italic;
  font-size: 28px;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;
  padding-right: 132px;
}

.back-timer-header {
  width: 575px;
  display: flex;
  align-items: center;
}

.back-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit; 
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-right: auto;
  margin-top: 15px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 35px;
  align-items: center;
  align-self: center;
}

.back-button:hover {
  color: var(--color-dark);
}

.help-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit; 
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-top: 15px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 35px;
  align-items: center;
  align-self: center;
}

.help-button:hover {
  color: var(--color-dark);
}

.close-button:hover {
  border:none;

  background-color: var(--color-normal); 
  color: #2D2D2D;}

.lists-container {
  overflow-y: auto; 
  display: flex;
  margin-top: 60px;
  margin-right: 40px;
}

.lists-container > div {
  flex: 1;   
}

.list-header {
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 25px;
}

.hint-text {
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-size: 20px;
  list-style-type: none; 
  padding-left: 0;
  align-items: center;
}

.hint-text li {
  margin: 5px 0; 
  list-style: none; 
  display: flex;
  align-items: left; 
}

.clue-content {
  display: flex; 
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
}

.clue-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.clue-number {
  font-weight: bold; 
  margin-right: 10px;
}

.clue-text {
  flex: 1;
}

li {
  margin-bottom: 10px;
}

.clues-container {  
  transform-origin: top left;
  flex: 2;
}

.popup-banner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
  position: fixed;
  width: 400px; 
  height: 600px; 

  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 

  color: rgb(46, 46, 46); 
  background-color: rgb(255, 255, 255);
  border: 4px solid #2D2D2D;
  padding: 20px; 
  z-index: 10; 
}

@media (max-width: 720px) {
  .popup-banner {
    transform: translate(-50%, -50%) scale(0.8);
  }
}

.close-button {
  margin-top: 30px;
  width: 200px;
  height: 50px;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  color: white;
  background-color: #2D2D2D;
  cursor: pointer;
  transition: background-color 0.3s;
}

.editable-area {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5; 
}

.selected-clue {
  background-color: var(--color-light); 
  font-weight: bold; 
}

.help-menu {
  position: fixed;
  width: 300px;
  background-color: #efefef;
  z-index: 10; 
  list-style: none; 
  padding: 0px;
  font-family: 'Inter', sans-serif;
  border: 4px solid #2D2D2D;
}

.help-item {
  background-color: white;
  padding: 8px 16px;
  cursor: pointer;
  color: #333;
  margin: 0px;
}

.mobile-help-item {
  background-color: white;
  padding: 8px 10px;
  cursor: pointer;
  color: #333;
  margin: 0px;
  font-size: 14px;
}

.mobile-help-item:hover {
  background-color: var(--color-light);
}

.help-menu-warning {
  background-color: var(--color-dark);
  font-weight: bold;
  font-style: italic;
  padding: 8px 16px;
  color: white;
  margin: 0px;
}

.mobile-help-menu-warning {
  background-color: var(--color-dark);
  font-weight: bold;
  font-style: italic;
  font-size: 15px;
  padding: 8px 16px;
  color: white;
  margin: 0px;
}


.help-item:hover {
  background-color: var(--color-light);
}

.timer-container {
  display: flex;
  align-items: flex-end;
}

.pc-milliseconds {
  padding-bottom: 5px;
  padding-left: 4px;
  font-weight: normal;
  font-size: 16px;
  color: #666;
  font-style: italic;
  font-family: 'Inter', sans-serif !important;
}

.ultimate-container {
  display: flex;
}

/* mobile */
.mobile-back-timer-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform-origin: top left;
  padding-top: 17px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 10px;
  font-size: 35px;
}

.mobile-crossword-container {
  width: 400px;
  height: 100%;
  margin-left: 9px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.mobile-timer {
  font-family: 'Inter', sans-serif;
  width: 100px;
  font-style: italic;
  font-size: 30px;
  text-align: center;
  margin-top: 3px;
}

.mobile-back-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit; 
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  padding-left: 0px;
  margin-top: 3px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 35px;
  align-items: center;
  align-self: center;
}

.mobile-help-button {
  background: none;
  border: none;
  color: var(--color-normal);
  font: inherit; 
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-top: 3px;
  transition: background-color 0.3s, color 0.3s;
  font-size: 35px;
  align-items: center;
  align-self: center;
  margin-right: 10px;
}

.mobile-help-menu {
  position: fixed;
  width: 210px;
  background-color: #efefef;
  z-index: 10; 
  list-style: none; 
  padding: 0px;
  font-family: 'Inter', sans-serif;
  border: 4px solid #2D2D2D;
}

.mobile-hint {
  background-color: var(--color-light);
  color: #000000;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  padding: 20px 30px;
  box-sizing: border-box;
  position: relative;

  height: 60px;
  width: 100%;

  position: absolute;
  left: 0px;
  
  display: flex;         
  align-items: center;   
  justify-content: center;

  margin: 0px;
}

.mobile-ultimate-container {
  display: flex;
  flex-direction: column;
  width: 100vh;
  height: fit-content;
  margin-left: 20px;
  overflow-x: hidden; 
}

@media (min-width: 421px) {
  .grid {
    transform: scale(1);
  }
  .mobile-hint {
    top: 475px;
  }
}

@media (max-width: 720px) {
  .grid {
    transform: scale(0.65);
    margin-left: 19px;
  }
  .mobile-hint {
    top: 490px;
  }
}

@media (max-width: 420px) {
  .grid {
    transform: scale(0.57);
    margin-left: 33px;
  }
  .mobile-hint {
    top: 520px;
  }
}

@media (max-width: 400px) {
  .grid {
    transform: scale(0.50);
    margin-left: 33px;
  }
  .mobile-hint {
    top: 450px;
  }
}

@media (max-width: 380px) {
  .grid {
    transform: scale(0.41);
    margin-left: 53px;
  }  
  .mobile-hint {
    top: 405px;
  }
}

@media (max-width: 300px) {
  .grid {
    transform: scale(0.48);
  }
}