.leaderboard-container h3 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;

    font-size: 25px;
    font-family: 'Inter', sans-serif;
    font-style: italic;
  }
  
  .leaderboard-list {
    border: 2px solid #2D2D2D;
    max-height: 160px; 
    overflow-y: auto;
  }
  
  .leaderboard-entry {
    font-family: 'Inter', sans-serif;
    font-style: italic;

    display: flex;
    justify-content: space-between; 
      

    width: 270px;
    padding: 10px;
    background-color: #efefef;
    border-bottom: 1px solid #2D2D2D;
    text-align: center;
    transition: background-color 0.3s, color 0.3s;
  }

  .leaderboard-entry:hover {
    font-weight: bold;
    background-color: var(--color-light);
  }
  
  .leaderboard-entry:last-child {
    border-bottom: none;
  }

  .timer-banner {
    font-family: 'Inter', sans-serif;
    font-style: italic;
    font-size: 35px;
    text-align: center;
    margin-top: 5px;
    margin-left: 20px;
  }

  .banner-header {
    margin-top: 30px;
    font-size: 35px;
    font-weight: bold;
    font-style: italic;
    font-family: 'Inter', sans-serif;
    width: fit-content;
  }

  .your-rank-container {
    margin-top: 15px;
    display: column;
    text-align: center;

    font-size: 25px;
    font-weight: bold;
    font-style: italic;
    font-family: 'Inter', sans-serif;
    width: fit-content;
  }

  .leaderboard-entry .formatted-time {
    margin-right: 2px;
  }  
  
  .leaderboard-entry .milliseconds {
    font-weight: normal;
    font-size: 10px;
    color: #666;
    font-style: italic;
  }
  
  .username {
    flex: 1; 
    text-align: left; 
    padding-left: 10px; 
  }
  
  .time {
    flex-shrink: 0;
    text-align: left; 
    padding-right: 10px; 
  }

  .pc-close-button {
        margin-top: 25px;
        margin-bottom: 30px;
        width: 250px;
        height: 50px;
        font-size: 20px;
        font-family: 'Inter', sans-serif;
        color: white;
        background-color: #2D2D2D;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .pc-close-button:hover {
        border:none;
    
        background-color: var(--color-normal); 
        color: #2D2D2D;
    }

    .first-completion-message {
        color: var(--color-normal); 
        font-size: 14px; 
        padding: 10px;
        text-align: center;
        font-family: 'Inter', sans-serif;
    }

  .popup-banner {
    height: fit-content;
  }

.rating-section {
  margin-top: 10px;
  font-family: 'Inter', sans-serif;
  text-align: center;
  align-content: center;
}

.rating-section h4 {
  font-size: 20px;
  margin: 0px;
  margin-top: 20px;
  margin-bottom: 5px;
}

.rank-header {
  font-style: italic;
}

.rank-number {
  font-size: 16px; 
}

.rank-symbol {
  font-size: 12px;
  margin-right: 1px;
}

.new-skill-rating {
  padding-top: 5px;
  margin-left: 30px;
  font-size: 16px;
  color: #666;
}

.rank-rating-container {
  display: flex;
}

.rank-text {
  margin-left: 75px;
}

.pc-help-button {
  background: none;
  border: none;
  color: var(--color-dark);
  font: inherit; 
  display: flex;
  align-items: center;
  padding: 5px 10px;
  margin-left: auto;
  font-size: 40px;
  align-items: center;
  align-self: center;
}